import React from 'react'
import AdminVolunteersSubmissions from '../collections/AdminVolunteers'

export default function VolunteersSub() {
  return (
    <div>
      <AdminVolunteersSubmissions />
    </div>
  )
}

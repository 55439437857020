import React from 'react'
import AdminPreRegistrations from '../../admin/collections/AdminPreRegistrations'

export default function RegistrationSub() {
  return (
    <div>
      <AdminPreRegistrations />
    </div>
  )
}

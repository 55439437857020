import React, { useState, useEffect } from 'react';
import PartnerLogo from './PartnerLogo'; 
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Define your partner data
const partners = [
  {
    name: 'Google',
    logoSrc: '/assets/svgs/anothest.png',
    url: 'https://google.com',
  },
  {
    name: 'Google',
    logoSrc: '/assets/svgs/Logos.png',
    url: 'https://google.com',
  },
  {
    name: 'Google',
    logoSrc: '/assets/svgs/joy.png',
    url: 'https://google.com',
  },
  {
    name: 'Google',
    logoSrc: '/assets/svgs/4.png',                                                                                                                                                                                                                                                        
    url: 'https://google.com',
  },
  // Add more partners as needed
];

const PartnersSection = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a network request
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the time as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="bg-white py-12">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-black mb-8 text-[#7F5283]">Conference Organizers</h2>
        <div className="flex flex-wrap justify-center items-center gap-16">
          {loading
            ? Array.from({ length: partners.length }).map((_, index) => (
                <div key={index} className="w-32 h-32">
                  <Skeleton circle height={64} width={64} />
                </div>
              ))
            : partners.map((partner, index) => (
                <PartnerLogo key={index} {...partner} />
              ))
          }
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;

// components/footer/AdminFooter.jsx
import React from 'react';

const AdminFooter = () => {
  return (
    <footer>
      <p>Admin Footer Content</p>
    </footer>
  );
};

export default AdminFooter;

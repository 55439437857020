import React from 'react'
import AdminNominations from '../collections/AdminNominations'

export default function NominationsSub() {
  return (
    <div>
      <AdminNominations />
    </div>
  )
}
